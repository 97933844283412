import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import { GlobalStyles } from "twin.macro"
import "typeface-open-sans"
import "typeface-montserrat"
import BottomNav from "./bottomNav"
import tw from 'twin.macro'
import Contact from "./contact"

const Wrapper = tw.div`bg-gray-100`
const WrapperBody = tw.div`my-0 mx-auto pt-2 px-4 pb-6`

const Layout = ({ children }) => {
  const [menuCollapsed, setMenuCollapsedstate] = useState("none")

  function handleMenuClick() {
    if (menuCollapsed === "block") {
      setMenuCollapsedstate("none")
      return
    }
    if (menuCollapsed === "none") {
      setMenuCollapsedstate("block")
      return
    }
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Header
        menuClick={handleMenuClick}
        menuCollapsed={menuCollapsed}
        siteTitle={data.site.siteMetadata?.title || `Title`}
      />
      <WrapperBody id="home"
        style={{
          maxWidth: 960,
        }}
      >
        <>
          <GlobalStyles />
          {children}
        </>
      </WrapperBody>
      <Contact/>
      <BottomNav/>
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
