import PropTypes from "prop-types"
import React from "react"
import tw from "twin.macro"
import { css } from "@emotion/core"

const StyledHeader = tw.header`w-full bg-gray-900 fixed top-0 z-50 sm:flex sm:justify-between sm:px-4 sm:py-3 sm:items-center`

const StyledContainer = tw.div`flex items-center justify-between px-4 py-3 sm:p-0`

const StyledTitle = tw.a`text-2xl text-white font-display font-bold tracking-wider`
const StyledMenuButton = tw.button`text-gray-500 focus:text-white hover:text-white focus:outline-none block sm:hidden`
const StyledMenuSvg = tw.svg`w-6 h-6 fill-current`

const StyledMenuContainer = tw.div`px-2 pt-2 pb-4 sm:block sm:flex sm:p-0`
const StyledLink = tw.a`block text-white font-body font-semibold tracking-wider rounded px-2 hover:bg-gray-800 mt-1 sm:mt-0 sm:ml-2 `

const Header = ({ siteTitle, menuClick, menuCollapsed }) => {
  console.log("render ... HEADER")
  return (
    <StyledHeader>
      <StyledContainer>
        <div>
          <StyledTitle href="#home">{siteTitle}</StyledTitle>
        </div>
        <div>
          <StyledMenuButton onClick={() => menuClick()} type="button">
            <StyledMenuSvg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 5C3 4.44772 3.44772 4 4 4H16C16.5523 4 17 4.44772 17 5C17 5.55228 16.5523 6 16 6H4C3.44772 6 3 5.55228 3 5Z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 10C3 9.44772 3.44772 9 4 9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H4C3.44772 11 3 10.5523 3 10Z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 15C3 14.4477 3.44772 14 4 14H16C16.5523 14 17 14.4477 17 15C17 15.5523 16.5523 16 16 16H4C3.44772 16 3 15.5523 3 15Z"
              />
            </StyledMenuSvg>
          </StyledMenuButton>
        </div>
      </StyledContainer>
      <StyledMenuContainer
        css={css`
          display: ${menuCollapsed};
        `}
      >
        <StyledLink href="#projects">Projects</StyledLink>
        <StyledLink href="#profile" >About</StyledLink>
        <StyledLink href="#resume">Resume</StyledLink>
        <StyledLink href="#contact">Contact</StyledLink>
      </StyledMenuContainer>
    </StyledHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
